<template>
  <el-drawer
    class="custom-el-drawer"
    :append-to-body="appendToBody"
    :before-close="beforeClose"
    :close-on-press-escape="closeOnPressEscape"
    :custom-class="customClass"
    destroy-on-close
    :direction="direction"
    :visible="visible"
    :wrapper-closable="wrapperClosable"
    :with-header="false"
    @open="open"
    @opened="opened"
    @close="close"
    @closed="closed"
  >
    <el-container v-loading="loading">
      <el-header>
        <div class="title">{{ title }}</div>
        <i v-show="showClose" class="el-icon-close icon-close" @click="handleClose" />
      </el-header>
      <el-main>
        <slot />
      </el-main>
      <el-footer v-show="showFooter">
        <el-button @click="handleClose">取 消</el-button>
        <slot name="centerButton" :loading="loading" />
        <el-button type="primary" :loading="loading" @click="handleConfirm">确 认</el-button>
      </el-footer>
    </el-container>
  </el-drawer>
</template>

<script>
  export default {
    name: 'CustomDrawer',
    props: {
      appendToBody: {
        type: Boolean,
        default: true
      },
      closeOnPressEscape: {
        type: Boolean,
        default: false
      },
      customClass: {
        type: String,
        default: ''
      },
      direction: {
        type: String,
        default: 'rtl'
      },
      showClose: {
        type: Boolean,
        default: true
      },
      size: {
        type: String,
        default: '80%'
      },
      title: {
        type: String,
        default: ''
      },
      wrapperClosable: {
        type: Boolean,
        default: false
      },
      showFooter: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        loading: true,
        visible: false
      }
    },
    methods: {
      handleOpen() {
        this.visible = true
        this.loading = true
      },
      handleClose() {
        this.visible = false
      },
      handleConfirm() {
        this.$emit('handleConfirm')
      },
      openLoading() {
        this.loading = true
      },
      closeLoading() {
        setTimeout(() => {
          this.loading = false
        }, 500)
      },
      open() {
        this.$emit('openCallback')
      },
      opened() {
        this.$emit('openedCallback')
      },
      beforeClose() {
        this.$emit('beforeCloseCallback')
      },
      close() {
        this.$emit('closeCallback')
      },
      closed() {
        this.$emit('closedCallback')
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep .el-drawer {
    width: calc(100vw - 256px) !important;

    .el-drawer__body {
      height: 100%;

      .el-container {
        height: 100%;

        .el-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);

          .title {
            font-size: 20px;
            font-weight: 700;
          }

          .icon-close {
            cursor: pointer;
          }
        }

        .el-footer {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          box-shadow: 0 -2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        }
      }
    }
  }
</style>
